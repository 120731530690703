import React from 'react';
import ReactDOM from 'react-dom/client';
import { Users  } from '../components/users'

class PaxelUsersElement extends HTMLElement {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {
    super()
  }
  connectedCallback() {
    const mountPoint = document.createElement("div")
    this.appendChild(mountPoint)

    const root = ReactDOM.createRoot(mountPoint);
    root.render(<Users />)
    

  }
}
customElements.define('paxer-users', PaxelUsersElement)