import React, { useState, useEffect } from "react"
import { IPerson } from "./model/person"
import './MyComponent.css'


// interface IPerson {
//   firstName: String
//   lastName: string
//   gender: string
// }
type Props = {
  data: IPerson
}

export const MyComponent = ({data}: Props) => {
    // const [count, setCount ] = useState(0)
    const [person, setPerson ] = useState<IPerson>(data)


    useEffect(() => {
        // @ts-ignore
        console.log('MyComponent - Events -> ', window.fsEvents)
    
        const subID = window.fsEvents?.subscribe("person", (person: IPerson) => {
          console.log('MyComponent - Subscribe Person -> ', person)
          setPerson(person);
        })
    
        return () => {
          if (subID) {
            window.fsEvents?.unsubscribe(subID);
          }
        };
      }, []);
    return (
        <div className='mycomponent'>
            <div>
              <h2><b>Mi name is: </b>{person.firstName} {person.lastName}</h2>
              <p>This is a my first test of WC</p>
              <b>My gender is: {person.gender}</b>
              {/* <button className='btn btn-primary'>Click Me!!!!</button> */}
            </div>
        </div>
    )
}