import { create } from "zustand";
import { IPerson } from "./model/person";



interface State {
    person: IPerson;
    addPerson: (item: IPerson) => void;
}

const validItem = (item: IPerson) => {
  return !!item && typeof item === "object" && typeof item?.firstName === "string";
};

export const store = create<State>((set) => ({
  person: {
    lastName: '',
    firstName: '',
    gender: 'male'
  },
  addPerson: (newItem) =>{
    if(validItem(newItem)){
      set(() => {
        const person = newItem //{...state.person}
        console.log('4 - Input Person -> ', person)
        window.fsEvents.publish('person', person);
        return { person };
      })
    }
  }, 
  deletePerson: () => {
    console.log('eleiminar persona')
  }
}));

export const useStore = store;

export default useStore;