import React from 'react';
import ReactDOM from 'react-dom/client';
import { Form } from '../components/Form'

class PaxelFromElement extends HTMLElement {
  connectedCallback() {
    const mountPoint = document.createElement("div")
    this.appendChild(mountPoint)

    // const shadowRoot = this.attachShadow({ mode: 'open'})
    // shadowRoot.appendChild(mountPoint);

    const name = this.getAttribute('name') || 'Sin nombre'
    const root = ReactDOM.createRoot(mountPoint);
    root.render(<Form />)
    

  }
}
customElements.define('paxel-form', PaxelFromElement)