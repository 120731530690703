import { useEffect, useState } from "react"
import { IUsers } from "../../models/users"

export const Users = () =>  {
    console.log('Render Component Users')
    const [users, setUsers] = useState<IUsers[]>([])
    
    useEffect(() => {

        fetch('https://jsonplaceholder.typicode.com/users')
            .then(response => response.json())
            .then(json => {
                console.log(json)
                setUsers(json)
            })

    }, [])

    return (
        <div>
            List Users
            <div>
                {users.map((user) => (<p>{user.name}</p>))}
            </div>
        </div>
    )
} 