import ReactDOM from 'react-dom/client';
import {EventBus} from '../components/EventBus';
import PubSub from '../services/pubsub';

declare global {
    interface Window { fsEvents: any }
}

class PaxerBusEventElement extends HTMLElement {
    private mountPoint: HTMLSpanElement = document.createElement("span")
    
    connectedCallback() {
      const topic = this.getAttribute('topic') || 'init'
      console.log('Inicio Bus Event Topic -> ' , topic)
  
      const events = new PubSub({ persistedTopics: [topic] });
      window.fsEvents = events;
      this.mountPoint = document.createElement("span")
      this.render()
    }

    render() {
      const root = ReactDOM.createRoot(this.mountPoint)
      root.render(<></>)
    }
  }

customElements.define('paxer-bus-event', PaxerBusEventElement)
