import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form"
import { IPerson } from "../../models/person";
// import { IPerson } from "./model/person";
import { useStore, store } from './store'

enum GenderEnum {
    female = "female",
    male = "male",
    other = "other"
}

interface IFormInput {
    firstName: String
    lastName: string
    gender: GenderEnum
}

export default function Form() {
  const person = useStore((store) => store.person);
  const { register, handleSubmit } = useForm<IFormInput>();
  
  const onSubmit: SubmitHandler<IPerson> = data => {
      console.log('1- OnSubmit -> ', data);
      window.fsEvents?.publish("person", data)
  } 
  
  useEffect(() => {
        console.log('Form - events -> ', window.fsEvents)
        
        if (window.fsEvents) {
            window.fsEvents.subscribe("addPerson", (person: IPerson) => {
                console.log("3 - New Person -> ", person);
                // setCount(count + value)
            
                const { getState } = store;
                const { addPerson } = getState();
                addPerson(person);
            });
            
            window.fsEvents.publish("person", person);
        }

        console.log('Form - events -> ', window.fsEvents)

        
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-3">
            <label htmlFor="exampleFormControlInput1" className="form-label">Nombre</label>
            <input {...register("firstName")} className="form-control"/>
        </div>    
        <div className="mb-3">
            <label htmlFor="exampleFormControlInput1" className="form-label">Apellido</label>
            <input {...register("lastName")} className="form-control"/>
        </div>  
        <div className="mb-3">
            <label htmlFor="exampleFormControlInput1" className="form-label">Genero</label>
            <select {...register("gender")} className="form-control" >
                <option value="female">female</option>
                <option value="male">male</option>
                <option value="other">other</option>
            </select>
        </div>   
      <input type="submit" className="btn btn-success"/>
    </form>
  )
}