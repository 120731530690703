import React from 'react';
import ReactDOM from 'react-dom/client';
import { MyComponent } from '../components/MyComponent'


class MyComponentElement extends HTMLElement {
//   private mountPoint: HTMLSpanElement = document.createElement("div")
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {
    super()
  }
  
  connectedCallback() {
    const mountPoint = document.createElement("div")
    this.appendChild(mountPoint)


    const firstName = this.getAttribute('firstName') || ''
    const lastName = this.getAttribute('lastName') || ''
    const gender = this.getAttribute('gender') || 'female'
    const root = ReactDOM.createRoot(mountPoint);
    root.render(<MyComponent data={{firstName,lastName, gender}} />)
    

  }
}
customElements.define('my-component', MyComponentElement)